
import { defineComponent } from "vue";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    PlusOutlined,
    CloseOutlined,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    optionKey: {
      type: String,
      default: "option",
    },
  },
  data() {
    return {
      letter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "G"],
    };
  },
  methods: {
    add() {
      if (!(this as any).item[this.optionKey]) {
        (this as any).item[this.optionKey] = [];
      }
      if ((this as any).item[this.optionKey].length < this.letter.length) {
        (this as any).item[this.optionKey].push({});
      } else {
        (this as any).$message.warning(`最多只支持${this.letter.length}个选项`);
      }
    },
    rulesOption(index) {
      return [
        {
          trigger: "blur",
          validator: async (rule, value, item) => {
            if (!value[index].title) {
              return Promise.reject("请输入");
            }
            // let str: any = value.join("&/&*") + "&/&*";
            // for (let i = 0; i < value.length; i++) {
            //   if (
            //     str
            //       .replace(value[i] + "&/&*", "")
            //       .split("&/&*")
            //       .indexOf(value[i]) > -1
            //   ) {
            //     if (i === index) {
            //       return Promise.reject("选项不可重复");
            //     }
            //   }
            // }
          },
        },
      ];
    },
    //删除选项
    delOption(item, index) {
      this.item[this.optionKey].splice(index, 1);
    },
  },
  mounted() {},
});
