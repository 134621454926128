
import { defineComponent } from "vue";
import Upload from "@/components/modification/Upload.vue";

export default defineComponent({
  components: {
    Upload,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      imgSizeRules: (this as any).$store.state.rules.num({
        cb: () => {
          if (Number(this.item.imgSize) && Number(this.item.imgSize) > 10) {
            return {
              noPasse: true,
              text: "图片大小最大为10M",
            };
          }
          if (this.item.imgSize === "0") {
            return {
              noPasse: true,
              text: "不能等于0",
            };
          }
        },
      }),
      imgMaxCntRules: (this as any).$store.state.rules.num({
        cb: () => {
          if (
            (Number(this.item.imgMaxCnt) && Number(this.item.imgMaxCnt) > 8) ||
            this.item.imgMaxCnt === "0"
          ) {
            return {
              noPasse: true,
              text: "图片数量范围为1-8张",
            };
          }
        },
      }),
    };
  },
});
