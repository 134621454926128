import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1637eac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding-top":"20px","width":"600px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form_item, {
      label: "任务类型",
      name: "taskTypeCode",
      rules: _ctx.rules.taskTypeCode
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select, {
          value: _ctx.formState.taskTypeCode,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.taskTypeCode) = $event)),
          disabled: !_ctx.isEdit || this.$route.params.id !== 'new',
          placeholder: "请选择",
          allowClear: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, { value: "CTT000EXPANDTASK" }, {
              default: _withCtx(() => [
                _createTextVNode("拓店任务")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "CTT000ADDSTORE" }, {
              default: _withCtx(() => [
                _createTextVNode("新增店铺")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "disabled"])
      ]),
      _: 1
    }, 8, ["rules"]),
    _createVNode(_component_a_form_item, {
      label: "任务名称",
      name: "taskTitle",
      rules: _ctx.rules.taskTitle
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_input, {
          value: _ctx.formState.taskTitle,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.taskTitle) = $event)),
          valueModifiers: { trim: true },
          placeholder: "请输入",
          maxlength: 100,
          allowClear: "",
          size: "small",
          readonly: !_ctx.isEdit
        }, null, 8, ["value", "readonly"])
      ]),
      _: 1
    }, 8, ["rules"]),
    _createVNode(_component_a_form_item, {
      label: "备注",
      name: "memo",
      rules: _ctx.rules.memo
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_textarea, {
          value: _ctx.formState.memo,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.memo) = $event)),
          valueModifiers: { trim: true },
          placeholder: "请输入",
          maxlength: 1000,
          allowClear: "",
          showCount: "",
          readonly: !_ctx.isEdit
        }, null, 8, ["value", "readonly"])
      ]),
      _: 1
    }, 8, ["rules"])
  ]))
}