
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  props: {
    formState: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  methods: {
    link() {
      let path = "/template";
      if (this.$route.path.includes("templateApp")) {
        path = "/templateApp/list";
      }
      this.$router.push(path);
    },
  },
});
