
import { defineComponent } from "vue";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons-vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import Option from "@/components/template/carryOut/Option.vue";
import ImageConfig from "@/components/template/carryOut/ImageConfig.vue";
export default defineComponent({
  components: {
    PlusOutlined,
    CloseOutlined,
    DecorateModalTitle,
    Option,
    ImageConfig,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    optionKey: {
      type: String,
      default: "option",
    },
  },
  data() {
    return {
      charMaxCntRules: (this as any).$store.state.rules.num({ required: true }),
    };
  },
  methods: {
    add() {
      if (
        (this as any).item[this.optionKey].length <
        (this as any).$store.state.config.optionNum
      ) {
        (this as any).item[this.optionKey].push("");
      } else {
        (this as any).$message.warning(
          `最多只支持${(this as any).$store.state.config.optionNum}个选项`
        );
      }
    },
    rulesOption(index) {
      return [
        {
          trigger: "blur",
          validator: async (rule, value, item) => {
            // console.log(rule, value, item);
            if (!value[index]) {
              return Promise.reject("不可为空");
            }
          },
        },
      ];
    },
    //删除选项
    delOption(item, index) {
      // console.log(item, index);
    },
  },
  mounted() {},
});
