import request from '@/utils/request.js';
//如果走app相关的页面就在所有的请求路径上加上app
let baseUrl
if (location.href.includes('templateApp')) {
    baseUrl = 'App'
} else {
    baseUrl = ''
}
//上传图片  ${baseUrl}
export function imageUpload(formData, onUploadProgress) {
    return request()({
        url: `/api/taskhall/v1/mode/fileUpload`,
        method: 'post',
        data: formData,
        onUploadProgress
    })
}