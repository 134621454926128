
import { defineComponent, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { updateMode, getDetails, addMode } from "@/api/template/api.js";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import Config from "@/components/template/carryOut/Config.vue";
import ProblemSub from "@/components/template/carryOut/ProblemSub.vue";
export default defineComponent({
  components: {
    PlusOutlined,
    DecorateModalTitle,
    Config,
    ProblemSub,
    ExclamationCircleOutlined,
  },
  props: {
    //全部数据结构
    formState: {
      type: Object,
      default: {},
    },
    //子任务执行频次 仅当拓店任务的时候固定传0
    frequency: {
      type: Number,
      default: 0,
    },
    //子任务是否必做
    isMust: {
      type: Number,
      default: 1,
    },
    //当前配置单元
    item: {
      type: Object,
      default: {},
    },
    //当前配置单元索引值
    index: {
      type: Number,
      default: 0,
    },
    //问题的键值
    problemKey: {
      type: String,
      default: "problem",
    },
    //选择题选项键值
    optionKey: {
      type: String,
      default: "option",
    },
    //子任务键值
    taskSubKey: {
      type: String,
      default: "taskSub",
    },
    //任务包键值
    taskPackageKey: {
      type: String,
      default: "taskPackage",
    },
    //子任务的索引值 本期所有的子任务都只有一项所以固定0
    taskSubIndexKey: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    //是否禁用题型框
    disableType: {
      type: Boolean,
      default: false,
    },
    //是否显示前一题的按钮
    per: {
      type: Boolean,
      default: true,
    },
    //禁用上一题  小于多少之后不显示
    perDisabledIndex: {
      type: Number,
      default: -1,
    },
    //是否显示下一题的按钮
    next: {
      type: Boolean,
      default: true,
    },
    //禁用下一题 大于多少之后不显示
    nextDisabledIndex: {
      type: Number,
      default: 9999,
    },
    type: {
      type: String,
      default: "",
    },
    platform: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      // 采集项类型0：问答；1：单选；2：多选；3：图片上传(单张)；4：图片上传(多张)
      // text问答，number数字，date日期，radio单选, checkbox多选，picture图片上传(单张)，multiplepictures图片上传(多张)，money金额，telephone电话
      typeOptions: [
        {
          value: "text",
          label: "问答题",
        },
        {
          value: "radio",
          label: "单选题",
        },
        {
          value: "checkbox",
          label: "多选题",
        },
        {
          value: "picture",
          label: "图片收集单张",
        },
        {
          value: "multiplepictures",
          label: "图片收集多张",
        },
        {
          value: "number",
          label: "数量",
        },
        {
          value: "money",
          label: "金额",
        },
        {
          value: "telephone",
          label: "电话",
        },
      ],
      visible: false,
      keepItem: "",
      previewItem: {},
      previewIndex: 0,
      rules: {
        itemType: [
          {
            trigger: "blur",
            required: true,
            validator: async (rule, value) => {
              if (!value && value !== 0) {
                return Promise.reject(`请选择`);
              }
            },
            message: "请选择",
          },
        ],
        title: [
          {
            trigger: "blur",
            required: true,
            message: "请输入",
          },
        ],
        isMust: [
          {
            trigger: "blur",
            required: true,
            validator: async (rule, value) => {
              if (!value && value !== 0) {
                return Promise.reject(`请选择`);
              }
            },
            message: "请选择",
          },
        ],
        optionAdd: [
          {
            validator: async (rule, value) => {
              if (
                this.previewItem[this.optionKey] &&
                this.previewItem[this.optionKey].length === 0
              ) {
                return Promise.reject(`请添加选项`);
              } else if (this.previewItem[this.optionKey].length <= 1) {
                return Promise.reject(`至少两个选项`);
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    beforeLeave() {
      // console.log(this.keepItem === JSON.stringify(this.previewItem));
      return new Promise((resolve, reject) => {
        if (this.keepItem === JSON.stringify(this.previewItem)) {
          resolve(true);
        } else {
          let that = this;
          Modal.confirm({
            title: "提示",
            icon: () => createVNode(ExclamationCircleOutlined),
            content: "当前问题配置已更改但尚未保存,是否确定要离开",
            okText: "离开",
            cancelText: "返回修改",
            onOk() {
              //还原数据
              that.$nextTick(() => {
                that.updateItem(JSON.parse(that.keepItem));
              });
              resolve(true);
            },
            onCancel() {
              resolve(false);
            },
          });
        }
      });
    },
    //更新缓存的数据
    updateKeep(data) {
      if (data === undefined) {
        data = this.previewItem;
      }
      this.keepItem = JSON.stringify(data);
    },
    show(item) {
      this.previewItem = JSON.parse(JSON.stringify(this.item));
      this.previewIndex = this.index;
      this.updateKeep(item);
      this.visible = true;
    },
    async onClose(e) {
      this.visible = true;
      let res = await this.beforeLeave();
      if (res) {
        this.visible = false;
        //清除校验
        (this as any).$refs.formRef.clearValidate();
        this.$emit("onClose", JSON.parse(this.keepItem));
      }
    },
    //点击提交触发
    async submit() {
      let res = await this.sub();
      if (res) {
        this.visible = false;
      }
    },
    //提交前先发请求
    sub() {
      return new Promise((resolve, reject) => {
        (this as any).$refs.formRef.validate().then(() => {
          let templateFormState = (this as any).$store.state.template
            .templateFormState;
          let item = JSON.parse(JSON.stringify(this.previewItem));
          item.status = 1;
          // try {
          //   let tempOptions =
          //     templateFormState[this.taskPackageKey][this.taskSubKey][
          //       this.taskSubIndexKey
          //     ][this.problemKey][this.previewIndex][this.optionKey];
          //   item[this.optionKey] = [...tempOptions, ...item[this.optionKey]];
          //   if (item[this.optionKey]) {
          //     for (let i = 0; i < item[this.optionKey].length; i++) {
          //       const element = item[this.optionKey][i];
          //       // if (i < tempOptions.length) {
          //         element.status = 0;
          //       // } else {
          //       //   element.status = 1;
          //       // }
          //     }
          //   }
          //   console.log(tempOptions, item[this.optionKey]);
          // } catch {}
          // 情况2
          // item[this.optionKey].forEach((element) => {
          //   element.status = 1;
          // });
          let param = {
            id: templateFormState.id,
            [this.taskPackageKey + "Code"]:
              templateFormState[this.taskPackageKey + "Code"] || null,
            [this.taskPackageKey]: {
              packageType: 0, //任务包类型:关联的任务类型,0：执行模板；1：执行任务，2：非执行任务，3：任务库 固定传0
              publishPort: (this as any).$store.state.template.checkPublishPort(
                templateFormState.taskTypeCode
              ),
              performPort: (this as any).$store.state.template.checkPerformPort(
                templateFormState.taskTypeCode
              ),
              [this.taskSubKey]: [
                {
                  code:
                    templateFormState[this.taskPackageKey][this.taskSubKey][
                      this.taskSubIndexKey
                    ].code || null,
                  [this.problemKey]: [item],
                  frequency: this.frequency, //子任务执行频次 拓店任务固定传0
                  must: this.isMust, //子任务是否必做
                },
              ],
            },
          };
          updateMode(param).then((res) => {
            if (res.data.success) {
              (this as any).$store.state.template
                .getNewTemplateFormState(this.$route.params.id, this.type)
                .then((res1) => {
                  if (res1) {
                    (this as any).$store.commit(
                      "template/templateFormState",
                      res1
                    ); //如果新建包的时候需要更新任务包code  子任务code 单个新增采集项的code
                    (this as any).previewItem.code =
                      (this as any).previewItem.code || res.data.data.subCode;
                    this.formState[this.problemKey] =
                      res1[this.taskPackageKey][this.taskSubKey][
                        this.taskSubIndexKey
                      ][this.problemKey];
                    resolve(true);
                  }
                });
            }
          });
        });
      });
    },
    //更新数据
    updateItem(data) {
      if (this.formState[this.problemKey].length - 1 >= this.previewIndex) {
        this.formState[this.problemKey][this.previewIndex] = data;
      }
    },
    //上一题
    async toPer() {
      this.$emit("per");
      //切换到上一条数据
      // this.$emit(
      //   "update:item",
      //   this.formState[this.problemKey][this.index - 1]
      // );
      // this.$emit("update:index", this.index - 1);
      this.previewItem = JSON.parse(
        JSON.stringify(this.formState[this.problemKey][this.previewIndex - 1])
      );
      //更新keepItem内部缓存的数据
      this.updateKeep(this.previewItem);
      this.previewIndex = this.previewIndex - 1;
    },
    //点击上一题触发
    async onPer() {
      //数据未变更
      if (this.keepItem === JSON.stringify(this.previewItem)) {
        this.toPer();
      } else {
        //数据变更
        let res = await this.sub();
        if (res) {
          this.toPer();
        }
      }
    },
    //切换至下一题
    toNext() {
      this.$emit("next");
      //切换到下一条数据
      if (this.previewIndex >= this.formState[this.problemKey].length - 1) {
        let obj = {};
        obj[this.optionKey] = [];
        this.previewItem = obj;
        this.updateKeep(obj);
      } else {
        // this.$emit(
        //   "update:item",
        //   this.formState[this.problemKey][this.index + 1]
        // );
        this.previewItem = JSON.parse(
          JSON.stringify(this.formState[this.problemKey][this.previewIndex + 1])
        );
        //更新keepItem内部缓存的数据
        this.updateKeep(this.formState[this.problemKey][this.previewIndex + 1]);
      }
      // this.$emit("update:index", this.index + 1);
      this.previewIndex = this.previewIndex + 1;
    },
    //点击下一题触发
    async onNext() {
      //数据未变更
      if (this.keepItem === JSON.stringify(this.previewItem)) {
        this.toNext();
      } else {
        //数据变更
        let res = await this.sub();
        if (res) {
          this.toNext();
        }
      }
    },
  },
  watch: {
    item() {
      this.previewItem = JSON.parse(JSON.stringify(this.item));
      this.previewIndex = this.index;
    },
  },
  created() {
    this.previewItem = JSON.parse(JSON.stringify(this.item));
    this.previewIndex = this.index;
  },
});
