
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  props: {
    formState: {
      type: Object,
      default: {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rules: {
        taskTitle: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        taskTypeCode: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
        taskTCode: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        memo: [
          {
            message: "请输入",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {},
  mounted() {},
});
